import React from "react";
import "./style.css";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <img className="logo" src={require("../../assets/icons/logo/2x.png")} />
        <div className="txtContainer">
          <p>
            피스폴솔루션즈 주식회사
            <br />
            <br />
            대표 : 임용석
            <br />
            사업자 등록번호 : 647-81-02771
            <br />
            통신판매업 신고번호 : 2023-충북청주-0146
            <br />
            주소 : 충청북도 청주시 서원구 1순환로 771, 4층 (개신동)
            <br />
            문의 : peacefallsol@gmail.com
            <br />
            <br />
            <div style={{ display: "flex", cursor: "pointer" }}>
              <p onClick={() => window.open("https://banjjack.com/privacy_policy.html", "_balck")}>
                <b style={{ fontWeight: "bold" }}> 개인정보 처리방침 </b>
              </p>
              <p>&nbsp; | &nbsp;</p>
              <p onClick={() => window.open("https://banjjack.com/termsOfUse.html", "_balck")}> 이용약관 </p>
            </div>
            <br />
            Copyright © 2022 Peacefallsolutions Inc. All Rights Reserved.
          </p>
          <img src={require("../../assets/imgs/ISO/2x.png")} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import "./style.css";

const Main = () => {
  return (
    <section className="first">
      <img className="logo" src={require("../../assets/icons/logo/2x.png")} />
      <div className="container">
        <div className="textInfo">
          <p className="mean">
            <span>반</span>려동물 <span>짝</span>꿍찾기 큐<span>피</span>드 <span>플</span>랫폼
          </p>
          <h1>
            Let's <span>반짝피플!</span>
          </h1>
          <p className="subTitle">
            이성 친구, 동성 친구, 댕댕이 친구까지
            <br />
            20~30대 싱글 반려인 여기 모두 모여라~
          </p>
          <div className="btnsBox">
            <button onClick={() => window.open("https://play.google.com/store/apps/details?id=com.ban_jjack", "_blank")} className="store" type="button">
              <img src={require("../../assets/imgs/btn/googlePlay/2x.png")} />
            </button>
            <button onClick={() => window.open("https://apps.apple.com/app/1665490045", "_blank")} className="store" type="button">
              <img src={require("../../assets/imgs/btn/appStore/2x.png")} />
            </button>
          </div>
        </div>
        <div className="appImg_Box">
          <img className="app" src={require("../../assets/imgs/app/adventure/1/2x.png")} />
        </div>
        <div className="btnsBox mo">
          <button onClick={() => window.open("https://play.google.com/store/apps/details?id=com.ban_jjack", "_blank")} className="store" type="button">
            <img src={require("../../assets/imgs/btn/googlePlay/2x.png")} />
          </button>
          <button onClick={() => window.open("https://apps.apple.com/app/1665490045", "_blank")} className="store" type="button">
            <img src={require("../../assets/imgs/btn/appStore/2x.png")} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Main;

import React from "react";
import "./style.css";

const Advantage = () => {
  return (
    <section id="advantage">
      <div className="container">
        <div className="headerBox">
          <h1>
            우리가 반짝이는 이유
            <br />
            Let's 반짝피플~!
          </h1>
          <img className="illustration" src={require("../../assets/imgs/illustration/adventure/2x.png")} />
        </div>
      </div>
      <div className="imgContainer">
        <div className="imgBox">
          <img className="img" src={require("../../assets/imgs/card/advantage/1/2x.png")} />
          <img className="img" src={require("../../assets/imgs/card/advantage/2/2x.png")} />
          <img className="img" src={require("../../assets/imgs/card/advantage/3/2x.png")} />
        </div>
      </div>
    </section>
  );
};

export default Advantage;

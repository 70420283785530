import "./App.css";
import Main from "./components/main";
import { Reset } from "styled-reset";
import Introduce from "./components/introduce";
import Store from "./components/store";
import Footer from "./components/footer";
import Advantage from "./components/advantages";
import Review from "./components/review";
import { useState } from "react";

function App() {
  const [isPopUp, setIsPopUp] = useState(true);

  return (
    <div className="App">
      {isPopUp && (
        <div className="popUp">
          <div className="popUpBox">
            <button onClick={() => setIsPopUp(false)}>
              <img className="x" src={require("../src/assets/icons/x/1x.png")} />
            </button>
            <img src={require("../src/assets/imgs/popUp/popUp.png")} />
          </div>
        </div>
      )}
      <Reset />
      <Main />
      {isPopUp === false && (
        <>
          <Introduce />
          <Advantage />
          <Review />
          <Store />
          <Footer />
        </>
      )}
      {/* {isPopUp && ( */}

      {/* )} */}
    </div>
  );
}

export default App;

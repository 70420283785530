import React from "react";
import "./style.css";

const Store = () => {
  return (
    <section id="store">
      <div className="container">
        <h1>
          반짝피플에서 주변 이웃들과
          <br />
          건강한 반려문화를 형성해보세요.
        </h1>
        <div className="btnsBox">
          <button onClick={() => window.open("https://play.google.com/store/apps/details?id=com.ban_jjack", "_blank")} className="store" type="button">
            <img src={require("../../assets/imgs/btn/googlePlay/2x.png")} />
          </button>
          <button onClick={() => window.open("https://apps.apple.com/app/1665490045", "_blank")} className="store" type="button">
            <img src={require("../../assets/imgs/btn/appStore/2x.png")} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Store;

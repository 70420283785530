import React from "react";
import "./style.css";

const introduceContents = [
  {
    titleFirst: "매일 주어지는 추천카드를 통해",
    titleSecond: "동네 이웃 만들기",
    subTitle: "마음에 드는 이웃을 선택하고 대화해보세요.",
    imgFirst: require("../../assets/imgs/app/neighbor/1/2x.png"),
    imgSecond: require("../../assets/imgs/app/neighbor/2/2x.png"),
  },
  {
    titleFirst: "우리 아이의",
    titleSecond: "동물 친구 만들기",
    subTitle: "외로운 반려동물을 위해 동물 친구를 찾아주세요.",
    imgFirst: require("../../assets/imgs/app/pet/1/2x.png"),
    imgSecond: require("../../assets/imgs/app/pet/2/2x.png"),
  },
  {
    titleFirst: "매일 기록되는",
    titleSecond: "우리의 탐험 일지",
    subTitle: "주변 이웃들의 위치를 확인하고 함께 산책해보세요.",
    imgFirst: require("../../assets/imgs/app/adventure/2/2x.png"),
    imgSecond: require("../../assets/imgs/app/adventure/3/2x.png"),
  },
  {
    titleFirst: "다양한 정보를 공유하는",
    titleSecond: "반짝 커뮤니티",
    subTitle: "사돈맺기, 입/분양, 중고거래 등 다양한 정보를 공유해보세요.",
    imgFirst: require("../../assets/imgs/app/community/1/2x.png"),
    imgSecond: require("../../assets/imgs/app/community/2/2x.png"),
  },
  {
    titleFirst: "매주 코인이 쏟아지는",
    titleSecond: "랭킹 시스템",
    subTitle: "반려동물과의 사진을 공유하고 무료 코인을 획득하세요.",
    imgFirst: require("../../assets/imgs/app/ranking/1/2x.png"),
    imgSecond: require("../../assets/imgs/app/ranking/2/2x.png"),
  },
];

const Introduce = () => {
  return (
    <section id="introduce">
      <div className="container">
        {introduceContents.map((content) => {
          return (
            <div key={content.title} className="boxsContainer">
              <div className="txtsBox">
                <h1>
                  {content.titleFirst}
                  <p>{content.titleSecond}</p>
                </h1>
                <h2>{content.subTitle}</h2>
              </div>
              <div className="imgsBox">
                <img className="first" src={content.imgFirst} />
                <img className="second" src={content.imgSecond} />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Introduce;

import React, { useEffect, useState } from "react";

import "./style.css";

const reviewImgs = [require("../../assets/imgs/card/review/1/2x.png"), require("../../assets/imgs/card/review/2/2x.png"), require("../../assets/imgs/card/review/3/2x.png")];

const Review = () => {
  return (
    <section id="review">
      <div className="container">
        <h1>반짝피플 이용 후기</h1>
        <div className="imgContainer">
          <div className="imgBox">
            {reviewImgs.map((img, index) => {
              return <img className="img" key={index.toString()} src={img} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Review;
